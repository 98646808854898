import { PATHS, ROUTES } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { useIsMatch } from 'hooks';
import { FunctionComponent, useContext } from 'react';
import classnames from 'classnames';
import { Container } from 'components/containers';
import { Link } from 'react-router-dom';
import { Image } from 'components/images';

import HeaderContext from './context';
import DesktopMenu from './DesktopMenu';

const DesktopHeader: FunctionComponent = () => {
  const isHome = useIsMatch(PATHS.index);
  const filled = useContext(HeaderContext);
  const company = useClientState((state) => state.company.item);

  const DESKTOP_HEADER = classnames('h-16 lg:flex flex-col justify-center hidden', {
    'bg-header': filled || !isHome,
  });

  return (
    <div className={DESKTOP_HEADER}>
      <Container className="flex items-center h-16">
        <Link to={ROUTES.index} className="w-auto">
          <Image src={company.document('logo')} className="h-8 w-auto" />
        </Link>
        <DesktopMenu />
      </Container>
    </div>
  );
};

export default DesktopHeader;
