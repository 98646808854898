const POSTAL_CODE_LENGHT = 5;
const POSTAL_CODE_REGEX = /^[0-9]+$/;
const PHONE_MATCH = /^\+?(\d[\d\s-]{7,14})(\([\d\s-]+\))?$/;
const URBAN = 'Urbano';
const CUPS_MATCH = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{0,1}[a-zA-Z]{0,1}$/;
const CAU_MATCH = /^ES[0-9]{16}[a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}A000$/;
const NUMBER_MATCH = /^[0-9]*([.|,][0-9]{0,2})?$/;

export {
  POSTAL_CODE_LENGHT,
  POSTAL_CODE_REGEX,
  PHONE_MATCH,
  URBAN,
  CUPS_MATCH,
  CAU_MATCH,
  NUMBER_MATCH,
};
